import "./App.css";

function App() {
  return (
    <div
      className="App"
      style={{
        height: "100vh",
        background: "#00193a",
        overflow: "hidden",
        position: "relative",
        display:"flex",
        justifyContent:"center"
        ,alignItems:"center"
      }}
    >
      <div className="" style={{maxWidth:"300px"}}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Layer_1"
          x="0px"
          width="100%"
          height="100%"
          y="0px"
          viewBox="0 0 462 471"
          style={{ enableBackground: "new 0 0 462 471" }}
          xmlSpace="preserve"
        >
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html:
                "\n\t.st0{display:none;fill:#00193A;}\n\t.st1{display:none;}\n\t.st2{display:inline;fill:#00193A;}\n\t.st3{display:inline;fill:url(#SVGID_1_);}\n\t.st4{fill:none;stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;}\n\t.st5{fill:none;}\n\t.st6{fill:#FFFFFF;stroke:#FFFFFF;stroke-miterlimit:10;}\n",
            }}
          />
          <rect x={-730} y={-307} className="st0" width={1920} height={1080} />
          <g className="st1">
            <rect
              x={-730}
              y={-307}
              className="st2"
              width={1920}
              height={1080}
            />
            <radialGradient
              id="SVGID_1_"
              cx={-320}
              cy={111}
              r="428.0005"
              gradientTransform="matrix(2.4 0 0 1.35 1022 107.45)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} style={{ stopColor: "#0075C9" }} />
              <stop offset="0.1315" style={{ stopColor: "#0070C1" }} />
              <stop offset="0.3327" style={{ stopColor: "#0062AC" }} />
              <stop offset="0.577" style={{ stopColor: "#004C89" }} />
              <stop offset="0.7982" style={{ stopColor: "#003464" }} />
              <stop offset={1} style={{ stopColor: "#00193A" }} />
            </radialGradient>
            <path
              className="st3"
              d="M1190,495.7V19c-131.9-163.6-395.3-286.5-714.3-326H32.3C-332-261.9-623.8-108-730,91v332.6   c81.4,152.5,271.8,278.5,520.3,349.4h927.4C925.9,713.7,1093.3,615.6,1190,495.7z"
            />
          </g>
          <path
            className="st4"
            d="M129.2,106.6h-15.4c-2.6,0-4.7,2.1-4.7,4.7v83.8c0,2.6,2.1,4.6,4.6,4.6h15.5c2.6,0,4.6-2.1,4.6-4.6v-83.8  C133.9,108.7,131.8,106.6,129.2,106.6z"
          />
          <path
            className="st5"
            d="M391.7,288.5h11.5c2.8,0,5.1-2.3,5.1-5.1v-26.8c-7-0.7-14.2-1.2-21.7-1.4v28.2  C386.6,286.3,388.9,288.5,391.7,288.5z"
          />
          <path
            className="st6"
            d="M408.3,283.4c0,2.8-2.3,5.1-5.1,5.1h-11.5c-2.8,0-5.1-2.3-5.1-5.1v-28.2c-12.7-0.4-26.2-0.1-40.3,1v60.2  c0,2.6,2.1,4.7,4.7,4.7h54.8c1.4,0,2.5,1.1,2.5,2.5v35.2c0,1.7-1.4,3.1-3.1,3.1h-14.6c-1.7,0-3.1-1.4-3.1-3.1v-8.1  c0-3.8-3.1-6.9-6.9-6.9H354c-3.8,0-6.9,3.1-6.9,6.9v37.9c0,3.3,2.6,5.9,5.9,5.9h34.4h54.9c3.4,0,6.2-2.8,6.2-6.2V362v-40.8v-32.6  v-25.3c-12.4-2.9-25.8-5.2-40.3-6.6V283.4z"
          />
          <path
            className="st4"
            d="M386.6,255.2V65.7c0-4.2-3.4-7.7-7.7-7.7h-25c-4.2,0-7.7,3.4-7.7,7.7v190.5  C360.4,255.1,373.9,254.8,386.6,255.2z"
          />
          <path
            className="st4"
            d="M440.4,8.3h-23.9c-4.5,0-8.2,3.7-8.2,8.2v240.1c14.5,1.4,27.9,3.7,40.3,6.6V16.5C448.6,12,445,8.3,440.4,8.3z"
          />
          <path
            className="st6"
            d="M289.4,389.1c0,3,2.4,5.5,5.5,5.5h29.4c3,0,5.5-2.4,5.5-5.5V257.8c-12.9,1.5-26.3,3.7-40.3,6.7V389.1z"
          />
          <path
            className="st4"
            d="M335,40.9h46.2c3,0,5.5-2.4,5.5-5.5V13.8c0-3-2.4-5.5-5.5-5.5h-51.4h-40.3h-92.1c-3.2,0-5.7,2.6-5.7,5.7v21.1  c0,3.2,2.6,5.7,5.7,5.7h86.9c2.9,0,5.2,2.3,5.2,5.2v218.4c14-3,27.5-5.1,40.3-6.7V46.1C329.7,43.2,332.1,40.9,335,40.9z"
          />
          <path
            className="st6"
            d="M11.4,321.2c0,2.5,2,4.6,4.6,4.6h35.8h12.4c2.6,0,4.6,2.1,4.6,4.6v26.9c0,2.6-2.1,4.7-4.7,4.7h-7.7  c-2.6,0-4.7-2.1-4.7-4.7v-8.9c0-2.5-2-4.6-4.6-4.6H15.9c-2.5,0-4.6,2-4.6,4.6v41.3c0,2.7,2.2,4.8,4.8,4.8h88c2.7,0,4.8-2.2,4.8-4.8  v-91.8c0-2.6-2.1-4.6-4.6-4.6h-9.2H68.7H56.3c-2.5,0-4.6-2-4.6-4.6v-12.7c-14.2-3.6-27.7-7.8-40.3-12.4V321.2z"
          />
          <path
            className="st4"
            d="M57,40.9h69.9c2.4,0,4.4,2,4.4,4.4v6.6c0,2.7,2.2,5,5,5h31.3c2.5,0,4.6-2.1,4.6-4.6V13.1c0-2.7-2.2-4.8-4.8-4.8  H51.7H16.1c-2.6,0-4.7,2.1-4.7,4.7v250.5c12.7,4.6,26.1,8.8,40.3,12.4V46.2C51.7,43.3,54.1,40.9,57,40.9z"
          />
          <path
            className="st4"
            d="M174.2,78.8c0-2.6-2.1-4.8-4.8-4.8H73.5c-2.6,0-4.8,2.1-4.8,4.8v191.7c0,2.5,2,4.6,4.6,4.6h31.2  c2.5,0,4.6-2,4.6-4.6v-33.3c0-2.7,2.2-4.9,4.9-4.9h15c2.7,0,4.9,2.2,4.9,4.9v50.5c13.1,0.5,26.5,0.3,40.3-0.6V106.6V78.8z   M133.9,195.1c0,2.6-2.1,4.6-4.6,4.6h-15.5c-2.6,0-4.6-2.1-4.6-4.6v-83.8c0-2.6,2.1-4.7,4.7-4.7h15.4c2.6,0,4.7,2.1,4.7,4.7V195.1z"
          />
          <path
            className="st6"
            d="M133.9,287.8v102.1c0,2.6,2.1,4.7,4.7,4.7h31c2.6,0,4.7-2.1,4.7-4.7V287.2C160.4,288.1,147,288.3,133.9,287.8z"
          />
          <path
            className="st4"
            d="M236.6,232.4h32.5c2.4,0,4.4-2,4.4-4.4v-23.7c0-2.4-2-4.4-4.4-4.4h-32.4c-2.6,0-4.8-2.1-4.8-4.8V95.5  c0-2.7,2.2-4.9,4.9-4.9h32.4c2.4,0,4.3-1.9,4.3-4.3v-24c0-2.4-1.9-4.3-4.3-4.3h-37.3H197c-3,0-5.4,2.4-5.4,5.4v222.2  c13.2-1.5,26.6-3.7,40.3-6.8V237C231.9,234.4,234,232.4,236.6,232.4z"
          />
          <path
            className="st6"
            d="M191.6,389.6c0,2.7,2.2,5,5,5h30.4c2.7,0,5-2.2,5-5V278.8c-13.7,3-27.2,5.3-40.3,6.8V389.6z"
          />
          <path
            className="st6"
            d="M55.9,411.3H12.6v46.4h43.3c5.1,0,9.2-4.2,9.2-9.3v-27.8C65.1,415.5,61,411.3,55.9,411.3z M62.1,448.4  c0,3.4-2.8,6.2-6.2,6.2H15.7v-40.2h40.2c3.4,0,6.2,2.8,6.2,6.2V448.4z"
          />
          <path
            className="st6"
            d="M74.9,420.6v27.8c0,5.1,4.2,9.3,9.3,9.3h41.7v-3.1H84.2c-3.4,0-6.2-2.7-6.2-6.2v-12.3h47.9V433H78v-12.3  c0-3.4,2.7-6.2,6.2-6.2h41.7v-3.1H84.2C79,411.3,74.9,415.5,74.9,420.6z"
          />
          <path
            className="st6"
            d="M178.9,433h-34c-3.4,0-6.2-2.8-6.2-6.2v-6.2c0-3.4,2.7-6.2,6.2-6.2h41.7v-3.1h-41.7c-5.1,0-9.3,4.2-9.3,9.3v6.2  c0,5.1,4.2,9.3,9.3,9.3h34c3.4,0,6.2,2.7,6.2,6.2v6.2c0,3.4-2.8,6.2-6.2,6.2h-41.7v3.1h41.7c5.1,0,9.2-4.2,9.2-9.3v-6.2  C188.2,437.1,184,433,178.9,433z"
          />
          <rect x="197.9" y="411.3" className="st6" width="3.1" height="46.4" />
          <path
            className="st6"
            d="M232.4,436.1h27.8v12.3c0,3.4-2.8,6.2-6.2,6.2h-34c-3.4,0-6.2-2.7-6.2-6.2v-27.8c0-3.4,2.7-6.2,6.2-6.2h41.7  v-3.1H220c-5.1,0-9.3,4.2-9.3,9.3v27.8c0,5.1,4.2,9.3,9.3,9.3h34c5.1,0,9.2-4.2,9.2-9.3V433h-30.9V436.1z"
          />
          <path
            className="st6"
            d="M322.5,454.6h-3.1c-0.4,0-1.1-0.3-1.4-0.6l-35.1-41c-0.9-1-2.5-1.7-3.8-1.7h-3.1c-1.8,0-3.1,1.4-3.1,3.1v43.3  h3.1v-43.3h3.1c0.4,0,1.1,0.3,1.4,0.6l35.1,41c0.8,1,2.4,1.7,3.7,1.7h3.1c1.7,0,3.1-1.4,3.1-3.1v-43.2h-3.1V454.6z"
          />
          <path
            className="st6"
            d="M335.3,420.6v27.8c0,5.1,4.2,9.3,9.3,9.3h41.7v-3.1h-41.7c-3.4,0-6.2-2.7-6.2-6.2v-12.3h47.9V433h-47.9v-12.3  c0-3.4,2.7-6.2,6.2-6.2h41.7v-3.1h-41.7C339.4,411.3,335.3,415.5,335.3,420.6z"
          />
          <path
            className="st6"
            d="M439.3,411.3H396v46.4h3.1v-43.3h40.2c3.4,0,6.2,2.8,6.2,6.2v6.2c0,3.4-2.8,6.2-6.2,6.2h-37.1v3.1h37.1  c3.4,0,6.2,2.7,6.2,6.2v15.5h3.1v-15.5c0-3.2-1.6-6-4.2-7.7c2.5-1.7,4.2-4.5,4.2-7.7v-6.2C448.6,415.5,444.4,411.3,439.3,411.3z"
          />
        </svg>{" "}
        <div style={{marginTop:"15px"}} className="loader"></div>
      </div>
    </div>
  );
}

export default App;
